<template>
    <div style="background: var(--v-component-base) !important; height: 100%; width: 100%">
        <v-card flat>
            <div class="tabPage">
                <div class="d-flex" style="height: 40px; width: 100%; padding: 4px">
                    <v-spacer></v-spacer>
                        <v-chip>
                            <v-tooltip top open-delay="500">
                                <template v-slot:activator="{ on }">
                                    <v-btn small icon v-on="on" @click="beforeAddUser()">
                                        <v-icon small>add_circle</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 12px">Add User</span>
                            </v-tooltip>
                            <v-text-field placeholder="Search" class="mb-1" hide-details rounded clearable dense
                                style="width: 300px" v-model="search"></v-text-field>
                            <v-tooltip top v-if="filterActive" open-delay="500">
                                <template v-slot:activator="{ on }">
                                    <v-btn rounded right small icon color="redPop" @click="resetFilters" v-on="on">
                                        <v-icon>filter_alt_off</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 12px">Clear Filters</span>
                            </v-tooltip>
                        </v-chip>
                </div>
                <ag-grid-vue v-if="!$vuetify.breakpoint.mobile" groupDisplayType="multipleColumns"
                    :animateRows="false" :rowGroupPanelShow="'always'" :autoSizeStrategy="{
                            type: 'fitGridWidth',
                            defaultMinWidth: 100,
                            columnLimits: [{ colId: 'user.name', minWidth: 220 }],
                        }" style="width: 100%; height: calc(100vh - 150px); font-size: 11px" :columnDefs="headers"
                    :initialState="initialState" :rowData="orgUsers" :defaultColDef="defaultColDef"
                    :enableRangeSelection="true" :allowContextMenuWithControlKey="true"
                    :postProcessPopup="postProcessPopup" :getContextMenuItems="getContextMenuItems"
                    :statusBar="statusBar" :getRowId="getRowId" @grid-ready="onGridReady" :sideBar="sidebar"
                    @state-updated="onStateUpdated">
                </ag-grid-vue>
            </div>
        </v-card>

        <v-dialog v-model="userDialog" max-width="900px" :overlay-opacity="0.9" persistent>
            <v-card :loading="loading" style="max-height: 100vh; border-radius: 20px">
                    <v-row no-gutters style="height: 100%">
                        <v-col cols="12" sm="5" md="5" lg="5">
                            <v-card
                                style="height: 100%; width: 100%; background-color: var(--v-greyRaised-base) !important; border-radius: 20px 0 0 20px">
                                <!-- <v-subheader>
                                    <v-tooltip top open-delay="500">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon color="var(--v-info-base)" v-on="on" elevation="0" plain
                                                :disabled="processingUser"
                                                @click=""
                                                >
                                                <v-icon>person_add</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add Profile Picture</span>
                                    </v-tooltip>
                                </v-subheader> -->
                                <v-row no-gutters>
                                    <v-col cols="12" md="12" sm="12" lg="12" class="pt-3 pb-3 d-flex justify-center">
                                        <v-avatar class="ma-2" size="168">
                                            <v-img v-if="contact.avatar" :src="contact.avatar" />
                                            <v-icon v-else size="168">person</v-icon>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="12" sm="12" lg="12"
                                        class="pt-3 pb-3 d-flex align-center flex-column"
                                        v-if="!editingProfile && modalType != 'Add'">
                                        <span class="headline">{{ contact.firstname }} {{ contact.surname }}</span>
                                        <span class="caption pt-2 pb-5 font-weight-medium">{{ contact.position }}</span>
                                        <span class="caption pt-5 text--secondary">{{ contact.emailAddress }}</span>
                                        <span class="caption text--secondary">{{ contact.mobileNumber ?
                                            contact.mobileNumber :
                                            "000-000-0000" }}</span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="7" md="7" lg="7">
                            <v-card
                                style="border-radius: 0 20px 20px 0; background-color: var(--v-background-base) !important; contain: content">
                                <v-toolbar dense
                                    style="border-radius: 0 20px 0 0; background-color: var(--v-background-base) !important"
                                    flat>
                                    <v-spacer></v-spacer>
                                    <v-tooltip top open-delay="500">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon color="var(--v-info-base)" v-on="on" elevation="0" plain
                                                :disabled="validateContact() || processingUser"
                                                @click="modalType == 'Edit' ? saveUser() : addUser()"
                                                >
                                                <v-icon>save</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Save User</span>
                                    </v-tooltip>
                                    <v-tooltip top v-if="contact.id" open-delay="500">
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="sendUserInvite(contact)" :loading="sendingUserInvite" icon plain :disabled="savingUser || processingUser"
                                                class="mx-1" v-on="on" color="white"><v-icon>email</v-icon></v-btn>
                                        </template>
                                        <span>Send User Invite</span>
                                    </v-tooltip>
                                    <v-tooltip top v-if="contact.id" open-delay="500">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" icon color="red" elevation="0" @click="removeUser()" plain :disabled="sendingUserInvite || savingUser || processingUser">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Remove user from organisation</span>
                                    </v-tooltip>

                                    <v-btn plain depressed elevation="0" @click="clearDialog()" :disabled="sendingUserInvite || savingUser || processingUser">
                                        <v-icon color="redPop">close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <div class="dialogInputPage">
                                    <div class="dialogInput">
                                        <v-text-field v-model="contact.firstname" outlined dense hide-details
                                            label="First Name*" />
                                    </div>
                                    <div class="dialogInput">
                                        <v-text-field v-model="contact.surname" outlined dense hide-details
                                            label="Last Name" />
                                    </div>
                                    <div class="dialogInput">
                                        <v-text-field v-model="contact.position" outlined dense hide-details
                                            label="Position" />
                                    </div>
                                    <div class="dialogInput">
                                        <v-text-field v-model="contact.mobileNumber" outlined dense hide-details
                                            label="Mobile Number" />
                                    </div>
                                    <div class="dialogInput">
                                        <v-text-field v-model="contact.emailAddress" outlined dense hide-details
                                            label="Email Address*" />
                                    </div>
                                    <div class="dialogInput">
                                        <v-select small-chips dense outlined v-model="contact.organisationAddressId"
                                            label="User Office" :items="addresses" item-value="id" hide-details
                                            item-text="alias">
                                        </v-select>
                                    </div>
                                    <div class="dialogInput">
                                        <v-select dense multiple chips outlined closable :items="orgTeams" hide-details
                                            label="User Teams" item-value="id" item-text="name" v-model="selectedTeams"
                                            append-icon="groups">
                                            <template #selection="{ item }">
                                                <v-chip small :color="item.colour">{{ item.name }}</v-chip>
                                            </template>
                                        </v-select>
                                    </div>
                                    <div class="dialogInput">
                                        <v-select small-chips dense multiple chips outlined closable :items="roles"
                                            hide-details label="User Roles" item-value="id" item-text="name"
                                            v-model="selectedRoles" append-icon="manage_accounts">
                                        </v-select>
                                    </div>
                                    <div style="padding-top: 8px;">
                                        <v-select hide-details dense outlined v-model="contact.authProvider"
                                            label="Authentication Provider*" :items="['Google', 'System']">
                                        </v-select>
                                    </div>
                                    <div style="padding-bottom: 8px;">
                                        <v-checkbox dense hide-details v-model="contact.shared" label="Shared Contact">
                                        </v-checkbox>
                                        <small>
                                            Someone who represents your company when clients search for your business in our
                                            system.
                                        </small>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
            </v-card>
        </v-dialog>
        
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import ActionPanel from "./UsersComponents/ActionPanel.vue";
import RolesPanel from "./UsersComponents/RolesPanel.vue";
import TeamsPanel from "./UsersComponents/TeamsPanel.vue";
export default {
    components: {
        AgGridVue,
        ActionPanel,
        RolesPanel,
        TeamsPanel
    },
    data: () => ({
        savingUser: false,
        processingUser: false,

        sideBar: {},
        initialState: {},
        gridApi: {},
        defaultColDef: {
            sortable: true,
            resizable: true,
            filter: true,
            enableRowGroup: true,
            enablePivot: true,
            flex: 1,
            minWidth: 100,
            cellClass: "d-flex align-center",
            cellStyle: { textAlign: "center", fontSize: "11px" },
        },
        filterActive: false,
        searchTimeout: null,
        search: "",
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agFilteredRowCountComponent",
                },
                {
                    statusPanel: "agSelectedRowCountComponent",
                },
                {
                    statusPanel: "agAggregationComponent",
                },
            ],
        },

        addresses: [],
        contact: {},
        teamHeaders: [
            {
                text: "Action",
                align: "center",
                sortable: false,
                value: "action",
            },
            {
                text: "Name",
                align: "center",
                sortable: false,
                value: "name",
            },
        ],
        headers: [
            {
                headerName: "Name",
                field: "user.firstname",
            },
            {
                headerName: "Surname",
                field: "user.surname",
            },
            {
                headerName: "Email",
                field: "user.emailAddress",
            },
            {
                headerName: "Mobile Number",
                field: "user.mobileNumber",
            },
            {
                headerName: "Position",
                field: "position",
            },
            {
                headerName: "Office",
                field: "organisationAddress.alias",
            },
            {
                headerName: "Shared",
                field: "shared",
                sortable: false,
            },
            {
                headerName: "Auth Provider",
                field: "user.authProvider",
            },
            {
                headerName: "Roles",
                field: "user.userRoles",
                valueGetter: (params) => {
                    let data = params.data.user.userRoles
                    let arr = data.map((element) => {
                        return element.role.name;
                    })
                    return arr;
                },
            },
            {
                headerName: "Teams",
                field: "user.userTeams",
                valueGetter: (params) => {
                    let data = params.data.userTeams
                    let arr = data.map((element) => {
                        return element.team.name;
                    })
                    return arr;
                },
            },
        ],
        loading: false,
        loadingEdit: false,
        editingProfile: false,
        orgUsers: null,
        orgTeams: [],
        contactTeams: [],
        selectedTeams: [],
        roles: [],
        contactRoles: [],
        selectedRoles: [],
        // tabRefresh: 0,
        tempUsers: [],
        sortBy: "user.firstname",
        sortDesc: false,
        modalType: "",
        sendingUserInvite: false,
        users: {
            count: 0,
            data: [],
        },
        userDialog: false,
        userPage: 1,
        userParams: {
            limit: 100,
            offset: 0,
            search: null,
        },
        userSearch: "",
    }),
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
    watch: {
        search: {
            immediate: true,
            handler(val) {
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout);
                }
                this.searchTimeout = setTimeout(() => {
                    this.searchTimeout = undefined;
                    this.gridApi.setGridOption("quickFilterText", val);
                }, 500);
            },
        },
    },
    async created() {
        this.sidebar = {
            toolPanels: [
                {
                    id: "actions",
                    labelDefault: "Actions",
                    labelKey: "actions",
                    iconKey: "chart",
                    toolPanel: "ActionPanel",
                    minWidth: 300,
                    maxWidth: 500,
                    width: 300,
                    toolPanelParams: {
                        resetTable: this.resetTable,
                        exportExcel: () => {
                            this.gridApi.exportDataAsExcel();
                        },
                        exportCSV: () => {
                            this.gridApi.exportDataAsCsv();
                        },
                    },
                },
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                    },
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250,
                },
                {
                    id: "teams",
                    labelDefault: "Teams",
                    labelKey: "teams",
                    iconKey: "chart",
                    toolPanel: "TeamsPanel",
                    minWidth: 300,
                    maxWidth: 500,
                    width: 300,
                    toolPanelParams: (params) => ({
                        resetTable: this.resetTable,
                        updateTable: this.loadOrganisation,
                        refreshPanel: this.refreshToolPanel,
                        exportExcel: () => {
                            this.gridApi.exportDataAsExcel();
                        },
                        exportCSV: () => {
                            this.gridApi.exportDataAsCsv();
                        },
                    }),
                },
                {
                    id: "roles",
                    labelDefault: "Roles",
                    labelKey: "Roles",
                    iconKey: "chart",
                    toolPanel: "RolesPanel",
                    minWidth: 300,
                    maxWidth: 500,
                    width: 300,
                    toolPanelParams: {
                        resetTable: this.resetTable,
                        updateTable: this.getOrganisationUsers,
                        refreshPanel: this.refreshToolPanel,
                        exportExcel: () => {
                            this.gridApi.exportDataAsExcel();
                        },
                        exportCSV: () => {
                            this.gridApi.exportDataAsCsv();
                        },
                    },
                },
            ],
            defaultToolPanel: "ActionPanel",
        };
        await this.loadOrganisation();
    },
    methods: {
        // CREATED
        async loadOrganisation() {
            await this.getOrganisationUsers();
            await this.getRoles();
            await this.getTeams();
            await this.getOrganisationAddresses();
        },
        // GET
        async getTeams() {
            return this.orgTeams = await this.$API.getTeams();
        },
        async getRoles() {
            return this.roles = await this.$API.getOrganisationRole();
        },
        async getOrganisationUsers() {
            this.loading = true;
            this.orgUsers = await this.$API.getOrganisationUsers();
            this.loading = false;
        },
        async getOrganisationAddresses() {
            return this.addresses = await this.$API.getOrganisationAddress();
        },
        // ADD
        async beforeAddUser() {
            this.contact = { authProvider: "System" };
            this.modalType = "Add";
            await this.getTeams();
            await this.getRoles();
            await this.getOrganisationAddresses();
            this.userDialog = true;
        },
        async addUser() {
            try {
                this.loading = true;
                let contact = await this.$API.createUser(this.contact);
                let orgUser = await this.$API.createOrganisationUser({
                    userId: contact.id,
                    shared: this.contact.shared,
                    position: this.contact.position,
                    organisationAddressId: this.contact.organisationAddressId,
                });
                this.contact.orgUserId = orgUser.id;
                this.contact.id = contact.id;
                await this.saveTeamsAndRoles();
                this.loading = false;
                this.clearDialog();
                this.$message.success('User created successfully!')
            } catch (e) {
                this.$message.error("Creating user failed.");
            }
        },
        async addUserTeams(teams) {
            for (let i = 0; i < teams.length; i++) {
                let userTeam = await this.$API.createTeamUser({
                    teamId: teams[i].id,
                    organisationUserId: this.contact.orgUserId,
                });
                this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams.push(userTeam);
            }
        },
        async addUserRoles(roles) {
            for (let i = 0; i < roles.length; i++) {
                await this.$API.createUserRole({
                    roleId: roles[i].id,
                    userId: this.contact.id,
                });
            }
        },
        addUserToRole(user) {
            let index = this.orgUsers.indexOf(this.orgUsers.find((x) => x.id == user.id));
            this.orgUsers[index].addRole = true;
            this.orgUsers[index].userRoles = this.orgUsers[index].user.userRoles.map((x) => x.roleId);
            // this.tabRefresh++;
            this.$nextTick(() => {
                this.$refs["addToRoleInput_" + user.id].$refs.input.focus();
            });
        },
        // SAVE
        async saveUser() {
            try {
                this.loading = true;
                this.savingUser = true;
                await this.$API.updateUser({
                    id: this.contact.id,
                    firstname: this.contact.firstname,
                    surname: this.contact.surname,
                    emailAddress: this.contact.emailAddress,
                    authProvider: this.contact.authProvider,
                    mobileNumber: this.contact.mobileNumber,
                    position: this.contact.position,
                });
                await this.$API.updateOrganisationUser({
                    id: this.contact.orgUserId,
                    shared: this.contact.shared,
                    position: this.contact.position,
                    organisationAddressId: this.contact.organisationAddressId,
                });
                await this.saveTeamsAndRoles();
                this.$message.success("User saved successfully!");
            } catch (e) {
                console.log(e);
                this.$message.error("User update failed.");
            } finally {
                this.savingUser = false;
                this.loading = false;
                this.clearDialog();
            }
        },
        async saveTeamsAndRoles() {
            let selectedRoles = this.roles.filter((role) => this.selectedRoles.includes(role.id));
            await this.addUserRoles(this.getDifference(selectedRoles, this.contactRoles));
            await this.removeUserRoles(this.getDifference(this.contactRoles, selectedRoles));
            let selectedTeams = this.orgTeams.filter((team) => this.selectedTeams.includes(team.id));
            await this.addUserTeams(this.getDifference(selectedTeams, this.contactTeams));
            await this.removeUserTeams(this.getDifference(this.contactTeams, selectedTeams));
        },
        // REMOVE
        async removeUserRoles(roles) {
            for (let i = 0; i < roles.length; i++) {
                let userRoleId = this.contact.roles.filter((x) => x.roleId == roles[i].id);
                await this.$API.updateUserRole({
                    id: userRoleId[0].id,
                    isActive: false,
                    isDeleted: true,
                });
            }
        },
        async removeUserTeams(teams) {
            for (let i = 0; i < teams.length; i++) {
                let userTeam = teams[i].userTeams.filter((x) => x.organisationUserId == this.contact.orgUserId);
                await this.$API.updateTeamUser({
                    id: userTeam[0].id,
                    isActive: false,
                    isDeleted: true,
                });
                this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams.splice(this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams.indexOf(userTeam[0]), 1);
            }
        },
        async removeUser() {
            this.$confirm("Are you sure you want to remove this user?", "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            }).then(async () => {
                    this.processingUser = true;
                    for (let role of this.contact.roles) {
                        await this.$API.createUserRole({
                            batch: [{ id: role.id, isActive: false, isDeleted: true }]
                        })
                    }
                    await this.$API.updateOrganisationUser({
                        id: this.contact.orgUserId,
                        isActive: false,
                        isDeleted: true,
                    });
                    this.clearDialog();
                    this.$message.success("Successfully deleted!");
                }).catch((e) => {
                    console.error(e);
                    this.$message.error("Delete cancelled");
                }).finally(() => {
                    this.clearDialog();
                    this.processingUser = false;
                });
        },
        // UPDATE / EDIT
        async editUserDialog(user) {
            await this.getTeams();
            await this.getRoles();
            await this.getOrganisationAddresses();
            let row = user;
            this.modalType = "Edit";
            this.contact = {
                id: row.user.id,
                orgUserId: row.id,
                firstname: row.user.firstname,
                surname: row.user.surname,
                emailAddress: row.user.emailAddress,
                position: row.position,
                shared: row.shared,
                authProvider: row.user.authProvider,
                mobileNumber: row.user.mobileNumber,
                avatar: row.user.avatar,
                roles: row.user.userRoles,
                organisationAddressId: row.organisationAddressId,
            };
            this.selectedRoles = row.user.userRoles.map((x) => x.roleId);
            this.contactRoles = this.roles.filter((x) => this.selectedRoles.includes(x.id));
            this.contactTeams = this.orgTeams.filter((x) => x.userTeams.some((y) => y.organisationUserId == this.contact.orgUserId));
            this.selectedTeams = this.contactTeams.map((x) => x.id);
            this.userDialog = true;
        },
        async updateShareStatus(user) {
            try {
                user.shared = !user.shared;
                await this.$API.updateOrganisationUser({
                    id: user.id,
                    shared: user.shared,
                });
                this.$message.success("Successfully updated!");
            } catch (e) {
                this.$message.error("Update failed");
            }
        },
        // VALIDATION
        validateContact() {
            if (!this.validateEmail(this.contact.emailAddress) || !this.contact.authProvider || !this.contact.firstname) {
                return true;
            } else { 
                return false;
            }
        },
        validateEmail(email) {
            let result = false;
            if (email) {
                result = email.match(
                    //eslint-disable-next-line
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            }
            return result;
        },
        // OTHER
        clearDialog() {
            this.userDialog = false;
            this.contact = {};
            this.selectedRoles = [];
            this.contactRoles = [];
            this.contactTeams = [];
            this.selectedTeams = [];
            this.refreshToolPanel();
            this.getOrganisationUsers();
        },
        getDifference(array1, array2) {
            return array1.filter((object1) => {
                return !array2.some((object2) => {
                    return object1.id === object2.id;
                });
            });
        },
        async processUserRoleChanges(data) {
            let index = this.orgUsers.indexOf(this.orgUsers.find((x) => x.id == data.id));
            this.orgUsers[index].addRole = false;
            let add = data.userRoles.filter((x) => !data.user.userRoles.map((y) => y.roleId).includes(x));
            let remove = data.user.userRoles.filter((x) => !data.userRoles.includes(x.roleId));
            let obj = [
                ...add.map((x) => ({
                    userId: data.userId,
                    roleId: x,
                })),
                ...remove.map((x) => ({
                    id: x.id,
                    isActive: false,
                    isDeleted: true,
                })),
            ];
            if (obj.length > 0) {
                await this.$API.createUserRole({
                    batch: obj,
                });
                this.getOrganisationUsers();
            }
            // this.tabRefresh++;
        },
        async sendUserInvite(user) {
            this.sendingUserInvite = true;
            await this.$API.createEmailNotification("UserOrganisationInvite", {
                organisationId: this.$store.state.currentOrg.id,
                userId: user.id,
            });
            this.$message.success("Invite sent successfully!");
            this.sendingUserInvite = false;
        },
        filterUserChange(data) {
            this.userParams.filter = data;
            // this.getUsers()
        },
        // AG GRID
        refreshToolPanel() {
            this.gridApi.refreshToolPanel();
            const rolesPanel = this.gridApi.getToolPanelInstance('roles');
            if (rolesPanel) {
                rolesPanel.refresh();
            }
        },
        filterListener(event) {
            let filter = this.gridApi.getFilterModel();
            this.filterActive = Object.keys(filter).length > 0;
            this.filterSetting = filter;
        },
        getRowId(params) {
            return params.data.id;
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridApi.addEventListener("filterChanged", this.filterListener);
            this.getOrganisationUsers();
        },
        resetFilters() {
            this.gridApi.setFilterModel({});
        },
        resetTable() {
            this.gridApi.setFilterModel(null);
            this.gridApi.setRowGroupColumns([]);
            this.gridApi.applyColumnState({ state: this.columns });
            this.gridApi.refreshCells({ force: true });
        },
        onStateUpdated(state) {
            localStorage.setItem("contactTableState_" + this.$store.state.currentOrg.id, JSON.stringify(state.state));
        },
        postProcessPopup(params) {
            if (params.type !== "columnMenu") {
                return;
            }
            const ePopup = params.ePopup;
            ePopup.style.top = "65px";
            ePopup.style.left = params.column.actualWidth / 2 + params.mouseEvent.left + "px";
        },
        getContextMenuItems(params) {
            let result = [];
            if (!params.node.group) {
                result = [
                    {
                        name: "View User",
                        icon: '<span class="ag-icon ag-icon-eye" unselectable="on" role="presentation"></span>',
                        action: () => {
                            if (params.node && params.node.data && params.node.data.id) {
                                this.editUserDialog(params.node.data)
                            } else {
                                this.$message.error("Please select a single row to view the user");
                            }
                        },
                    },
                    "separator",
                    {
                        name: "Add to Filter",
                        icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
                        action: () => {
                            if (params.node && params.node.data) {
                                let filter = this.gridApi.getFilterModel();
                                if (filter[params.column.colId]) {
                                    filter[params.column.colId].values.push(params.value);
                                } else {
                                    filter[params.column.colId] = {
                                        values: [params.value],
                                    };
                                }
                                this.gridApi.setFilterModel(filter);
                            } else {
                                this.$message.error("Please select a single row to view the file");
                            }
                        },
                    },
                    "separator",
                    "copy",
                    "copyWithHeaders",
                    "export",
                    "chartRange",
                ];
            } else {
                result = ["copy", "copyWithHeaders", "export", "chartRange"];
            }

            return result;
        },
    },
};
</script>

<style lang="scss">
.add-user-button {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background: rgba(0, 0, 0, 0.025);
    }
}

.dialogInput {
    padding-top: 8px;
    padding-bottom: 8px;
}

.dialogInputPage {
    padding-right: 4px;
    padding-left: 4px;
}

.tabPage {
    padding-top: 0px;
    // margin-top: 12px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
}
</style>
